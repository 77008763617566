import { Container, Flex, Heading, SimpleGrid } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import RichTextPageContent from 'components/elements/RichTextPageContent'
import arrayHasValue from 'utils/arrayHasValue'
import getNumericSize from 'utils/getNumericSize'
import removeEmptyValues from 'utils/removeEmptyValues'

interface BlockImageWithTextListProps extends Contentful.IBlockImageWithTextList {}

const textAlignment = (element: Contentful.IBlockImageWithText) =>
  element.textAlignment?.includes('Right') ? 'right' : element?.textAlignment?.includes('Center') ? 'center' : 'left'

const TAG = 'BlockImageWithTextList'

const BlockImageWithTextList: FunctionComponent<BlockImageWithTextListProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }

  const elements = removeEmptyValues(pageBlock.elements?.items)
  const columnsCount = parseInt(pageBlock?.layout?.[0]?.match(/\d+/)?.[0] || '') || 4
  const elementWidth = columnsCount === 2 ? 'main-block' : columnsCount === 3 ? 'side-block-lg' : 'side-block'
  const imageWidth = getNumericSize(elementWidth) || 322
  const imageHeight = imageWidth / 1.67

  return arrayHasValue(elements) ? (
    <Container data-testid={TAG} maxW="desktop" p={10}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: columnsCount }} spacing={6}>
        {elements.map((element) => (
          <Flex key={element.sys.id} maxW={elementWidth} direction="column" align="center" textAlign="center">
            <Image
              data-contentful-field-id="image"
              data-contentful-entry-id={element.image.sys?.id}
              src={element.image?.image?.url || ''}
              blurDataURL={element.image?.image?.blurDataURL}
              alt=""
              role="presentation"
              width={imageWidth}
              height={imageHeight}
            />
            {element.heading && (
              <Heading
                as="h3"
                size="md"
                textTransform="uppercase"
                my={4}
                data-contentful-field-id="heading"
                data-contentful-entry-id={element.sys?.id}
              >
                {element.heading}
              </Heading>
            )}
            {element.description && (
              <RichTextPageContent
                richTextBodyField={element.description}
                data-contentful-field-id="description"
                data-contentful-entry-id={element.sys?.id}
                textAlign={textAlignment(element)}
              />
            )}
          </Flex>
        ))}
      </SimpleGrid>
    </Container>
  ) : null
}

export default BlockImageWithTextList
