import { Container, Box, Heading, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useRef } from 'react'

import DynamicForm from 'components/elements/DynamicForm'
import arrayHasValue from 'utils/arrayHasValue'
import getFormFields from 'utils/getFormFields'

interface BlockFormProps extends Contentful.IBlockForm {}

const styles = {
  container: { maxW: 'desktop', py: 0, px: 10, my: 6 },
  main: { w: '100%', maxW: 'main-block' },
  heading: { size: 'lg', mb: '6' },
  preamble: { fontSize: 'lg', mb: '6' },
} as const

const TAG = 'BlockForm'

const BlockForm: FunctionComponent<BlockFormProps> = (pageBlock) => {
  const {
    formName,
    anchorLink,
    fields: { url },
  } = pageBlock || {}
  const formElement = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const response = useQuery(['getFormFields', url], getFormFields, { enabled: !!url })

  const { data: formFields } = response

  // Workaround due to Next.js issue: https://github.com/vercel/next.js/issues/44295
  useEffect(() => {
    const hashLink = window.location.hash ? window.location.hash.replace('#', '') : undefined
    if (hashLink && anchorLink && hashLink === anchorLink && formElement.current) {
      formElement.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [anchorLink, router.isReady])

  return arrayHasValue(formFields) ? (
    <Container data-testid={TAG} {...styles.container} {...(anchorLink && { ref: formElement })}>
      <Box {...styles.main}>
        <Heading
          as="h2"
          {...styles.heading}
          data-contentful-field-id="heading"
          data-contentful-entry-id={pageBlock.sys?.id}
        >
          {pageBlock.heading}
        </Heading>
        <Text {...styles.preamble} data-contentful-field-id="preamble" data-contentful-entry-id={pageBlock.sys?.id}>
          {pageBlock.preamble}
        </Text>
        <DynamicForm formName={formName} fields={formFields} entryId={pageBlock.sys?.id} />
      </Box>
    </Container>
  ) : null
}

export default BlockForm
