import { Text, Button, Container, Stack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'

import DynamicField from 'components/elements/DynamicForm/DynamicField'
import { FormField, DynamicForm as Form } from 'components/elements/DynamicForm/types'
import CardAlert from 'components/elements/WarningAlert'
import { eloquaFormDefaultValues } from 'config/eloqua'
import useGoogleReCaptcha from 'hooks/useGoogleReCaptcha'
import formSubmitHandler from 'utils/formSubmitHandler'
import removeEmptyValues from 'utils/removeEmptyValues'

interface DynamicFormProps {
  formName: string
  fields: FormField[] | undefined
  entryId?: string
}

const styles = {
  container: { maxW: 'main-block-lg', py: 0, px: { base: 10, xl: 0 }, my: 6 },
  button: { w: 'min-content', mt: '40px !important' },
} as const

const TAG = 'DynamicForm'

const DynamicForm: FunctionComponent<DynamicFormProps> = ({ formName, fields, entryId }) => {
  const { t } = useTranslation()
  const { executeReCaptcha } = useGoogleReCaptcha()

  const form = useForm<Form>({
    defaultValues: { elqFormName: formName, ...eloquaFormDefaultValues },
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })

  const {
    handleSubmit,
    setError,
    register,
    setValue,
    formState: { errors, isValid, isSubmitting, isSubmitted, isSubmitSuccessful },
  } = form

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const reCaptchaResponseToken = await executeReCaptcha('LoadReCaptchaOnStart')
    return formSubmitHandler(
      reCaptchaResponseToken,
      { ...data, siteURL: window.location.href },
      t('components.dynamicForm.errors.error-form-api'),
      setError,
    )
  }

  const { query, isReady } = useRouter()

  useEffect(() => {
    if (isReady) {
      const { utm_campaign, utm_source, utm_medium } = query
      setValue('utm_campaign', utm_campaign?.toString() ?? '')
      setValue('utm_source', utm_source?.toString() ?? '')
      setValue('utm_medium', utm_medium?.toString() ?? '')
    }
  }, [isReady, query, setValue])

  return (
    <Container
      data-testid={TAG}
      {...styles.container}
      data-contentful-field-id="fields"
      data-contentful-entry-id={entryId}
    >
      <FormProvider {...form}>
        {isSubmitSuccessful && !errors?.apiError ? (
          <CardAlert>
            <Text>{t('components.dynamicForm.success.title')}</Text>
          </CardAlert>
        ) : (
          <Stack as="form" name="form" onSubmit={handleSubmit(onSubmit)} spacing={6}>
            <input type="hidden" {...register('elqFormName')} />
            <input type="hidden" {...register('elqSiteId')} />
            <input type="hidden" {...register('elqCampaignId')} />
            <input type="hidden" {...register('siteURL')} />
            <input type="hidden" {...register('utm_campaign')} />
            <input type="hidden" {...register('utm_source')} />
            <input type="hidden" {...register('utm_medium')} />

            {removeEmptyValues(fields).map((field) => (
              <DynamicField key={field.htmlName} field={field} />
            ))}

            <Button
              isLoading={isSubmitting}
              isDisabled={isSubmitting || (isSubmitted && !isValid)}
              type="submit"
              {...styles.button}
            >
              {t('components.dynamicForm.submit')}
            </Button>
          </Stack>
        )}
      </FormProvider>
    </Container>
  )
}

export default DynamicForm
