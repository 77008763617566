import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react'
import { FunctionComponent, useState } from 'react'

import Carousel, { NavProps } from 'components/elements/Carousel'
import Image from 'components/elements/Image'
import VideoEmbedded from 'components/elements/VideoEmbedded'
import removeEmptyValues from 'utils/removeEmptyValues'

const styles = {
  modalContent: { display: 'flex', alignItems: 'center', justifyContent: 'center', p: 0, maxH: '100dvh' },
  modalBody: {
    w: 'full',
    flex: '0',
    px: { base: 2, md: '100px' },
    pt: 8,
    sx: {
      '.swiper-slide': { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
  },
  button: { color: 'primary.red' },
  image: { maxHeight: '80vh', maxWidth: '100%' },
  video: { w: '70vw', maxW: 'main-block-lg' },
  flex: { justifyContent: 'space-between', w: 'full', py: '3', fontSize: '1rem' },
  caption: { maxW: { base: 'full', md: '50%' } },
  text: { ml: 4, minW: 8, fontSize: { base: 'sm', md: 'inherit' } },
} as const

const sharedStyles = {
  color: 'primary.black',
  bg: 'transparent',
  _hover: {
    color: 'primary.white',
    backgroundColor: 'primary.black',
  },
  _focusVisible: {
    color: 'primary.white',
    backgroundColor: 'primary.black',
  },
} as const

const navProps: NavProps = {
  prev: { left: '-90px', ...sharedStyles },
  next: { right: '-90px', ...sharedStyles },
}

interface ImagePreviewProps extends Omit<ModalProps, 'children'> {
  gallery?: Contentful.IBlockLinkList
  currentImageIndex?: number
}

/**
 * Used for E2E tests.
 */
const TAG = 'ImagePreview'

const ImagePreview: FunctionComponent<ImagePreviewProps> = ({
  isOpen,
  onClose,
  gallery,
  currentImageIndex,
  ...props
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1)
  if (!gallery) {
    return null
  }

  const close = () => {
    setCurrentSlideIndex(1)
    onClose()
  }

  return (
    <Modal id={TAG} closeOnOverlayClick={false} size="full" isOpen={isOpen} onClose={close} {...props}>
      <ModalOverlay />

      <ModalContent {...styles.modalContent}>
        <ModalCloseButton {...styles.button} />
        <ModalBody {...styles.modalBody}>
          <Carousel
            id={TAG}
            navProps={navProps}
            slidesPerView={1}
            loop={true}
            initialSlide={currentImageIndex}
            onSlideChange={(swiper) => setCurrentSlideIndex(swiper.realIndex + 1)}
            spaceBetween={8}
          >
            {(removeEmptyValues(gallery?.links?.items) as Contentful.IComponentMediaWrapper[]).map((item, index) => (
              <Flex direction="column" key={item.title} justifyContent="center">
                {item.mediaType?.[0] === 'Image' ? (
                  <Image
                    style={{ width: 'auto', height: '100%' }}
                    width={item?.image?.width || ''}
                    height={item?.image?.height || ''}
                    alt={item.caption || ''}
                    src={item.image?.url || ''}
                    {...styles.image}
                  />
                ) : item.mediaType?.[0] === 'Video' ? (
                  <VideoEmbedded
                    vimeoId={item.vimeoId}
                    title={item.title}
                    caption=""
                    {...(index === currentImageIndex ? { autoplay: true } : {})}
                    {...styles.video}
                  />
                ) : null}
                <Flex {...styles.flex}>
                  <Text {...styles.caption}>{item.caption}</Text>
                  <Text {...styles.text}>{`${currentSlideIndex} / ${gallery?.links?.items?.length}`}</Text>
                </Flex>
              </Flex>
            ))}
          </Carousel>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ImagePreview
