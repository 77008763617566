import { Container } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import ProfileCard from 'components/modules/Cards/ProfileCard'

interface BlockProfileProps extends Contentful.IBlockProfile {}

const TAG = 'BlockProfile'

const BlockProfile: FunctionComponent<BlockProfileProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }

  return (
    <Container data-testid={TAG} maxW="desktop-content" px={{ base: 10, xl: 0 }} py={10}>
      <ProfileCard profile={pageBlock} />
    </Container>
  )
}

export default BlockProfile
