import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Checkbox,
  VisuallyHidden,
  Select,
  Textarea,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, ReactNode } from 'react'
import { RegisterOptions, UseFormRegisterReturn, useFormContext } from 'react-hook-form'

import { FormField, InputType } from 'components/elements/DynamicForm/types'
import FormInput, { FormInputProps } from 'components/elements/FormInput'
import { getSelectOptions } from 'components/modules/Contact/ContactFormStepTwo'
import { EMAIL_REGEX, PHONE_REGEX } from 'config/contact'

const APPLY_LENGTH_VALIDATION: InputType[] = ['text', 'textarea', 'email', 'tel']

interface DynamicFieldProps {
  field: FormField
}

const TAG = 'DynamicField'

const DynamicField: FunctionComponent<DynamicFieldProps> = ({ field }) => {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
  } = useFormContext()

  if (!field) {
    return null
  }

  const fieldValidation: RegisterOptions = {
    ...(field.validation?.required && { required: t('components.dynamicForm.errors.error-missing-field') }),
    ...(APPLY_LENGTH_VALIDATION.includes(field.type) && {
      ...(field.validation?.minLength && {
        minLength: {
          value: field.validation?.minLength,
          message: t('components.dynamicForm.errors.error-min-length', { minLength: field.validation?.minLength }),
        },
      }),
      ...(field.validation?.maxLength && {
        maxLength: {
          value: field.validation?.maxLength,
          message: t('components.dynamicForm.errors.error-max-length', { maxLength: field.validation?.maxLength }),
        },
      }),
    }),
    ...(field.type.toString() === 'email' && {
      pattern: { value: EMAIL_REGEX, message: t('components.dynamicForm.errors.error-invalid-email') },
    }),
    ...(field.type.toString() === 'tel' && {
      pattern: { value: PHONE_REGEX, message: t('components.dynamicForm.errors.error-invalid-phone') },
    }),
  }

  const fieldRegister: UseFormRegisterReturn = register(field.htmlName, fieldValidation)
  const fieldProps = {
    key: field.htmlName,
    name: field.htmlName,
    help: field.helpText,
    label: field.label,
  } satisfies FormInputProps

  const props = { ...fieldProps, ...fieldRegister, ...{ errors } }

  const commonCountries = t('components.contactForm.countries.commonOptions', { count: 1 }, { returnObjects: true })
  const commonCountriesOptions = getSelectOptions(commonCountries)

  const otherCountries = t('components.contactForm.countries.otherOptions', { count: 1 }, { returnObjects: true })
  const otherCountriesOptions = getSelectOptions(otherCountries)

  function renderField(): ReactNode {
    switch (field.type) {
      case 'text':
      case 'email':
      case 'tel':
        return (
          <FormInput
            autoComplete="on"
            placeholder=" "
            type={field.type}
            inputMode={field.type}
            isRequired={field?.validation?.required}
            {...props}
          />
        )
      case 'textarea':
        return (
          <FormControl isInvalid={!!errors[field.htmlName]}>
            <Textarea placeholder=" " inputMode="text" size="xl" resize="none" borderRadius={2} {...props} />
            <FormLabel>{field.label}</FormLabel>
            <FormErrorMessage>
              {(errors[field.htmlName]?.message as string) ?? t('components.dynamicForm.errors.error-invalid-field')}
            </FormErrorMessage>
          </FormControl>
        )
      case 'dropdown':
        return (
          <FormInput
            as={Select}
            {...props}
            placeholder={field.label}
            label={<VisuallyHidden>{field.label}</VisuallyHidden>}
          >
            {field.dropdownOptions?.map((option: string) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </FormInput>
        )
      case 'checkbox':
        return (
          <FormControl as={Checkbox} isInvalid={!!errors[field.htmlName]} {...props}>
            <Text>{field.label}</Text>
            <FormErrorMessage>
              {(errors[field.htmlName]?.message as string) ?? t('components.dynamicForm.errors.error-invalid-field')}
            </FormErrorMessage>
          </FormControl>
        )
      case 'countrySelector':
        return (
          <FormInput
            as={Select}
            {...props}
            placeholder={field.label}
            label={<VisuallyHidden>{field.label}</VisuallyHidden>}
          >
            {commonCountriesOptions.map((country: string) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
            <option value="">------------</option>
            {otherCountriesOptions.map((country: string) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </FormInput>
        )
      default:
        return null
    }
  }

  return <>{renderField()}</>
}

DynamicField.displayName = TAG

export default DynamicField
