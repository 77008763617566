import { Heading, Container, StackProps } from '@chakra-ui/react'
import { FunctionComponent } from 'react'

import Carousel from 'components/elements/Carousel'
import ProductCard from 'components/modules/Product/ProductCard'
import arrayHasValue from 'utils/arrayHasValue'
import removeEmptyValues from 'utils/removeEmptyValues'

interface ProductsCarouselSectionProps extends StackProps {
  title: string
  titleId?: string
  entryId?: string
  products: ReadonlyArray<Contentful.IPageProductDetail> | undefined
}

/**
 * Used for E2E tests.
 */
const TAG = 'ProductsCarouselSection'

const spaceBetweenSlides = { base: 8, lg: 24 }

const ProductsCarouselSection: FunctionComponent<ProductsCarouselSectionProps> = ({
  title,
  titleId,
  products,
  entryId,
  ...props
}) => {
  return arrayHasValue(products) ? (
    <Container data-testid={TAG} maxW="100vw" p={{ base: 4, md: 10 }} bgColor="primary.grey.100" {...props}>
      <Container maxW="desktop" p={{ base: 0, lg: 10 }}>
        <Heading
          as="h2"
          size="lg"
          mb={8}
          mt={4}
          data-contentful-field-id={titleId ?? 'title'}
          data-contentful-entry-id={entryId}
        >
          {title}
        </Heading>

        <Carousel id={TAG} slideWidth={{ base: 288, lg: 322 }} spaceBetweenSlides={spaceBetweenSlides}>
          {removeEmptyValues(products).map((product) => (
            <ProductCard key={product.sys.id} product={product} />
          ))}
        </Carousel>
      </Container>
    </Container>
  ) : null
}

export default ProductsCarouselSection
