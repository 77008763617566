import { Container, Heading, SimpleGrid } from '@chakra-ui/react'
import { FunctionComponent } from 'react'

import FeaturedCard from 'components/modules/LandingPageCategory/FeaturedCard'
import arrayHasValue from 'utils/arrayHasValue'
import removeEmptyValues from 'utils/removeEmptyValues'

interface BlockFeaturedTeaserListProps extends Contentful.IBlockFeaturedTeaserList {}

const TAG = 'BlockFeaturedTeaserList'

function isSingeColumn(layout?: ArrayValues<Required<Contentful.IBlockFeaturedTeaserList>['layout']>): boolean {
  return layout === '1 column'
}

const BlockFeaturedTeaserList: FunctionComponent<BlockFeaturedTeaserListProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }

  const featuredItems = removeEmptyValues(pageBlock?.teaserLinks?.items)
  const isSingleColumn = isSingeColumn(pageBlock?.layout?.[0])

  return arrayHasValue(featuredItems) ? (
    <Container data-testid={TAG} maxW="desktop-content" p={{ base: 10, '2xl': 0 }}>
      {pageBlock.heading && (
        <Heading
          as="h2"
          size="lg"
          mt={16}
          mb={6}
          data-contentful-field-id="heading"
          data-contentful-entry-id={pageBlock.sys?.id}
        >
          {pageBlock.heading}
        </Heading>
      )}
      <SimpleGrid mb={16} data-items={featuredItems.length} columns={isSingleColumn ? 1 : { base: 1, lg: 2 }} gap={6}>
        {featuredItems.map((card, index) => (
          <FeaturedCard
            key={card.sys?.id}
            card={card}
            variant={isSingleColumn ? 'single' : 'multiple'}
            isReversed={index % 2 === 0}
          />
        ))}
      </SimpleGrid>
    </Container>
  ) : null
}

export default BlockFeaturedTeaserList
