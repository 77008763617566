import { Container, Flex, Heading } from '@chakra-ui/react'
import React, { FunctionComponent, ReactNode } from 'react'

import Carousel from 'components/elements/Carousel'
import PageCard from 'components/modules/LandingPageCategory/PageCard'
import arrayHasValue from 'utils/arrayHasValue'
import getNumericSize from 'utils/getNumericSize'
import removeEmptyValues from 'utils/removeEmptyValues'

interface BlockTeaserListProps extends Contentful.IBlockTeaserList {}

const styles = {
  main: {
    maxW: '100vw',
    p: { base: 4, md: 10 },
    bgColor: 'primary.grey.100',
  },
  wrapper: { maxW: 'desktop', p: { base: 0, lg: 10 } },
  heading: { size: 'lg', mb: 8, mt: 4 },
  flex: { direction: 'row', flexWrap: 'wrap', gap: 6 },
} as const

const getCardWidth = (layout: string | undefined) =>
  layout === '2 column'
    ? 'main-block'
    : layout === '3 column'
    ? 'side-block-lg'
    : layout === '4 column'
    ? 'side-block'
    : 'desktop-content'

const TAG = 'BlockTeaserList'

const BlockTeaserList: FunctionComponent<BlockTeaserListProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }

  const pages = removeEmptyValues(pageBlock.teaserLinks?.items)
  const cardWidth = getCardWidth(pageBlock?.layout?.[0])
  const slideWidth = getNumericSize(cardWidth) || 322

  const renderLinks = (children: ReactNode[]) =>
    pageBlock.displayGrid ? (
      <Flex {...styles.flex}>{children}</Flex>
    ) : (
      <Carousel
        id={TAG}
        slideWidth={{ base: 322, md: slideWidth }}
        spaceBetweenSlides={{ base: 8, lg: 24 }}
        shouldHideButtons
      >
        {children}
      </Carousel>
    )

  return arrayHasValue(pages) ? (
    <Container data-testid={TAG} {...styles.main}>
      <Container {...styles.wrapper}>
        <Heading
          as="h2"
          {...styles.heading}
          data-contentful-field-id="heading"
          data-contentful-entry-id={pageBlock.sys?.id}
        >
          {pageBlock.heading}
        </Heading>
        {renderLinks(
          pages.map((page) => (
            <PageCard key={page.sys.id} card={page} maxW={cardWidth} bodyStyles={{ px: 6, minH: '253px' }} />
          )),
        )}
      </Container>
    </Container>
  ) : null
}

export default BlockTeaserList
