import { AspectRatio, AspectRatioProps, Box, Skeleton, Text } from '@chakra-ui/react'
import React, { FunctionComponent, useEffect, useState } from 'react'

import VideoEmbeddedNotAllowed from 'components/elements/VideoEmbedded/VideoEmbeddedNotAllowed'

const VIMEO_PLAYER_URL = 'https://player.vimeo.com/video/' as const

const getVimeoVideoUrl = (id: string | undefined, autoplay?: boolean) =>
  `${VIMEO_PLAYER_URL}${id?.trim()}${autoplay ? '?autoplay=1' : ''}`

interface VideoEmbeddedProps extends AspectRatioProps {
  vimeoId: string | undefined
  title: string | undefined
  caption: string | undefined
  maxWidth?: ContainerSize | string
  autoplay?: boolean
}

const styles = {
  skeleton: {
    w: 'full',
    h: '360px',
  },
  caption: {
    mt: 4,
    noOfLines: 2,
    fontSize: 'xs',
    color: 'secondary.grey.900',
  },
} as const

const TAG = 'VideoEmbedded'

const VideoEmbedded: FunctionComponent<VideoEmbeddedProps> = ({
  vimeoId,
  title,
  caption,
  maxWidth,
  autoplay,
  ...props
}) => {
  const [videoSrc, setVideoSrc] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetch(`/api/vimeo/${vimeoId}`).then((res) => {
      if (res.status === 200) {
        setVideoSrc(getVimeoVideoUrl(vimeoId, autoplay))
      }
      setIsLoading(false)
    })
  }, [autoplay, vimeoId])

  return (
    <>
      {isLoading && <Skeleton {...styles.skeleton} {...props} />}
      {!isLoading && videoSrc && (
        <>
          <VideoEmbeddedNotAllowed />
          <Box className="embeddedBlock cookieconsent-optin-statistics" {...props}>
            <AspectRatio maxWidth={maxWidth} ratio={16 / 9}>
              <iframe
                title={title}
                allowFullScreen
                allow="autoplay"
                {...(window['Cookiebot']?.consent?.statistics
                  ? { src: videoSrc }
                  : { 'data-cookieconsent': 'statistics', 'data-cookieblock-src': videoSrc })}
              />
            </AspectRatio>
            {caption && <Text {...styles.caption}>{caption}</Text>}
          </Box>
        </>
      )}
    </>
  )
}

VideoEmbedded.displayName = TAG

export default VideoEmbedded
