import { Flex, Icon, Link, Text } from '@chakra-ui/react'
import Trans from 'next-translate/Trans'
import React, { FunctionComponent } from 'react'

import TruckIcon from 'assets/truck.svg?component'

const styles = {
  wrapper: {
    w: 'full',
    h: '440px',
    bg: 'primary.grey.500',
    flexDirection: 'column',
    p: 10,
  },
  iconWrapper: {
    boxSize: '11.5rem',
    fontSize: 'sm',
    borderRadius: '50%',
    bgColor: 'secondary.beige.100',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  icon: {
    boxSize: '5.75rem',
    color: 'primary.red',
  },
  text: {
    textAlign: 'center',
    mt: 4,
  },
} as const

const renewalHandler = () => window['Cookiebot'].renew()

const TAG = 'VideoEmbeddedNotAllowed'

const VideoEmbeddedNotAllowed: FunctionComponent = () => (
  <Flex className="cookieconsent-optout-statistics" {...styles.wrapper}>
    <Flex {...styles.iconWrapper}>
      <Icon as={TruckIcon} {...styles.icon} />
    </Flex>
    <Text {...styles.text}>
      <Trans
        i18nKey="components.embeddedVideo.video-not-allowed"
        components={{ Link: <Link onClick={renewalHandler} /> }}
      />
    </Text>
  </Flex>
)

VideoEmbeddedNotAllowed.displayName = TAG

export default VideoEmbeddedNotAllowed
