import { Container } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import React, { FunctionComponent } from 'react'

const DynamicVideoEmbed = dynamic(() => import('components/elements/VideoEmbedded'))

interface BlockVideoProps extends Contentful.IBlockVideo {}

const styles = {
  container: {
    maxW: 'desktop-content',
    py: 0,
    px: { base: 10, xl: 0 },
    my: 6,
  },
} as const

const TAG = 'BlockVideo'

const BlockVideo: FunctionComponent<BlockVideoProps> = (pageBlock) => {
  if (!pageBlock || !pageBlock?.video?.vimeoId || pageBlock?.video?.mediaType?.[0] !== 'Video') {
    return null
  }

  return (
    <Container
      data-testid={TAG}
      {...styles.container}
      data-contentful-field-id="vimeoId"
      data-contentful-entry-id={pageBlock.video.sys?.id}
    >
      <DynamicVideoEmbed
        vimeoId={pageBlock.video.vimeoId}
        caption={pageBlock.video.caption}
        title={pageBlock.video.caption}
        maxWidth={pageBlock.video.isFullWidth ? 'desktop-content' : 'main-block'}
      />
    </Container>
  )
}

export default BlockVideo
