import { Container, Flex } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import RichTextPageContent from 'components/elements/RichTextPageContent'
import { defaultRichTextStyles } from 'components/elements/RichTextPageContent/config'

interface BlockRichTextProps extends Contentful.IBlockRichText {}

const TAG = 'BlockRichText'

const BlockRichText: FunctionComponent<BlockRichTextProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }

  const displayMode = (pageBlock.mode?.[0] || 'Standalone') === 'Standalone' ? 'none' : 'block'

  return (
    <Container
      sx={{
        // When block is not full width, we should remove padding.
        '@container article not (min-width: calc(100vw - 1rem))': {
          p: 0,
        },
      }}
      data-testid={TAG}
      maxW="desktop-content"
      px={{ base: 10, xl: 0 }}
      py={10}
      display="flex"
      flexDirection="row"
      gap={{ base: 12, xl: '140px' }}
    >
      <Flex w="side-block" display={{ base: 'none', lg: displayMode }} />
      <RichTextPageContent
        richTextBodyField={pageBlock.richText}
        data-contentful-field-id="richText"
        data-contentful-entry-id={pageBlock.sys?.id}
        {...defaultRichTextStyles}
      />
    </Container>
  )
}

export default BlockRichText
