import { Container, Flex } from '@chakra-ui/react'
import React, { ComponentProps, FunctionComponent, ReactNode } from 'react'

import BlockContactUs from 'components/elements/Blocks/BlockContactUs'
import BlockFeaturedTeaserList from 'components/elements/Blocks/BlockFeaturedTeaserList'
import BlockForm from 'components/elements/Blocks/BlockForm'
import BlockImageGallery from 'components/elements/Blocks/BlockImageGallery'
import BlockImageWithTextList from 'components/elements/Blocks/BlockImageWithTextList'
import BlockProductGallery from 'components/elements/Blocks/BlockProductGallery'
import BlockProfile from 'components/elements/Blocks/BlockProfile'
import BlockPromotion from 'components/elements/Blocks/BlockPromotion'
import BlockRichText from 'components/elements/Blocks/BlockRichText'
import BlockSection from 'components/elements/Blocks/BlockSection'
import BlockTeaserList from 'components/elements/Blocks/BlockTeaserList'
import BlockVideo from 'components/elements/Blocks/BlockVideo'
import arrayHasValue from 'utils/arrayHasValue'
import removeEmptyValues from 'utils/removeEmptyValues'

interface BlocksManagerProps extends ComponentProps<typeof Container> {
  pageBlocks: ReadonlyArray<DynamicPaths.PageBlock> | undefined
}

function resolveElement(pageBlock: DynamicPaths.PageBlock): ReactNode {
  switch (pageBlock.contentType) {
    case 'BlockRichText':
      return <BlockRichText {...pageBlock} />
    case 'BlockProductGallery':
      return <BlockProductGallery {...pageBlock} />
    case 'BlockContactUs':
      return <BlockContactUs {...pageBlock} />
    case 'BlockPromotion':
      return <BlockPromotion {...pageBlock} />
    case 'BlockImageGallery':
      return <BlockImageGallery {...pageBlock} />
    case 'BlockSection':
      return <BlockSection {...pageBlock} />
    case 'BlockFeaturedTeaserList':
      return <BlockFeaturedTeaserList {...pageBlock} />
    case 'BlockTeaserList':
      return <BlockTeaserList {...pageBlock} />
    case 'BlockImageWithTextList':
      return <BlockImageWithTextList {...pageBlock} />
    case 'BlockProfile':
      return <BlockProfile {...pageBlock} />
    case 'BlockVideo':
      return <BlockVideo {...pageBlock} />
    case 'BlockForm':
      return <BlockForm {...pageBlock} />
    default:
      return null
  }
}

const TAG = 'BlocksManager'

const BlocksManager: FunctionComponent<BlocksManagerProps> = ({ pageBlocks, ...props }) => {
  if (!arrayHasValue(pageBlocks)) {
    return null
  }

  return (
    <Container data-testid={TAG} maxW="full" p={0} {...props}>
      {removeEmptyValues(pageBlocks)?.map((block) => (
        <Flex as="section" key={block.sys.id} w="full" justify="center">
          {resolveElement(block)}
        </Flex>
      ))}
    </Container>
  )
}

export default BlocksManager
