import { Card, CardBody, Text } from '@chakra-ui/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'

import Image from 'components/elements/Image'

interface GalleryCardProps {
  content?: string
  media?: Contentful.Asset
  mediaType?: Contentful.IComponentMediaWrapperFields['mediaType']
  vimeoId?: string
  maxW?: string
  currentGalleryIndex: number
  currentImageIndex: number
  onClick: (currentGalleryIndex: number, currentImageIndex: number) => void
}

/**
 * Used for E2E tests.
 */
const TAG = 'GalleryCard'

const GalleryCard: FunctionComponent<GalleryCardProps> = ({
  maxW = 'main-block-md',
  currentGalleryIndex,
  currentImageIndex,
  mediaType,
  vimeoId,
  media,
  content,
  onClick,
}) => {
  const [mediaSrc, setMediaSrc] = useState(media?.url)

  const handleClick = useCallback(
    () => onClick(currentGalleryIndex, currentImageIndex),
    [currentGalleryIndex, currentImageIndex, onClick],
  )

  useEffect(() => {
    if (mediaType?.[0] === 'Video') {
      fetch(`/api/vimeo/${vimeoId}`).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => setMediaSrc(data.thumbnailUrl))
        }
      })
    }
  }, [media?.url, mediaType, vimeoId])

  return (
    <Card
      data-testid={TAG}
      variant="unstyled"
      maxW={{ base: 'side-block-sm', lg: maxW }}
      borderRadius={0}
      bg="transparent"
    >
      <Image
        maxW="100%"
        minW="full"
        loading="lazy"
        src={mediaSrc || ''}
        alt={media?.title || ''}
        width={{ base: 320, lg: 780 }}
        height={{ base: 160, lg: 440 }}
        minHeight={{ base: 160, lg: 440 }}
        onClick={handleClick}
        blurDataURL={media?.blurDataURL}
        cursor="pointer"
      />
      <CardBody mt={4} maxW={{ base: '100%', md: '50%' }} display="flex" flexDirection="column">
        <Text>{content}</Text>
      </CardBody>
    </Card>
  )
}

export default GalleryCard
