import { Card, CardBody, Heading, Text, Flex } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import ContactButton from 'components/modules/Contact/ContactButton'

const TAG = 'ContactMainCard'

type Props = { pageBlock: Contentful.IBlockContactUs | undefined }

const ContactMainCard: FunctionComponent<Props> = ({ pageBlock }) => {
  if (!pageBlock) {
    return null
  }

  const { heading, text, ctaText, image, sys } = pageBlock

  return (
    <Card data-testid={TAG} variant="unstyled" borderRadius={0} w="full" maxW="main-block-lg">
      <CardBody display="flex" flexDir={{ base: 'column-reverse', md: 'row' }} justifyContent="end" gap={6}>
        <Flex w="full" maxW="side-block-lg" flexDir="column" pb={6}>
          <Heading size="lg" mb={4} data-contentful-field-id="heading" data-contentful-entry-id={sys?.id}>
            {heading}
          </Heading>
          <Text fontSize="lg" data-contentful-field-id="text" data-contentful-entry-id={sys?.id}>
            {text}
          </Text>
          <ContactButton
            mt={6}
            w={{ base: 'full', md: 'min-content' }}
            data-contentful-field-id="ctaText"
            data-contentful-entry-id={sys?.id}
            title={ctaText}
          />
        </Flex>

        {image && (
          <Image
            data-contentful-field-id="image"
            data-contentful-entry-id={image.sys?.id}
            src={image.image?.url || ''}
            alt={image.image?.title || ''}
            width="436"
            height="400"
            style={{ height: 'auto' }}
            blurDataURL={image.image?.blurDataURL}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default ContactMainCard
