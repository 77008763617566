import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ProductsCarouselSection from 'components/modules/Product/ProductsCarouselSection'

interface BlockProductGalleryProps extends Contentful.IBlockProductGallery {}

const TAG = 'BlockProductGallery'

const BlockProductGallery: FunctionComponent<BlockProductGalleryProps> = (pageBlock) => {
  const { t } = useTranslation()

  if (!pageBlock) {
    return null
  }

  return (
    <ProductsCarouselSection
      data-testid={TAG}
      titleId="heading"
      entryId={pageBlock?.sys?.id}
      title={pageBlock?.heading || t('pages.productDetail.sections.related-accessories')}
      products={pageBlock?.products?.items}
      bg="secondary.grey.100"
    />
  )
}

export default BlockProductGallery
