import { Container } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import ContactMainCard from 'components/modules/Contact/ContactMainCard'

interface BlockContactUsProps extends Contentful.IBlockContactUs {}

const TAG = 'BlockContactUs'

const BlockContactUs: FunctionComponent<BlockContactUsProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }

  return (
    <Container
      data-testid={TAG}
      maxW="desktop-content"
      display="flex"
      justifyContent="end"
      px={{ base: 10, xl: 0 }}
      py={10}
    >
      <ContactMainCard pageBlock={pageBlock} />
    </Container>
  )
}

export default BlockContactUs
