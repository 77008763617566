import { Box, Flex, Heading, Text, Container } from '@chakra-ui/react'
import NextLink from 'next/link'
import React, { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import NavigationItem from 'components/elements/NavigationItem'
import RichTextPageContent from 'components/elements/RichTextPageContent'
import hasProperty from 'utils/hasProperty'

interface BlockSectionProps extends Contentful.IBlockSection {}

const TAG = 'BlockSection'

const IMAGE_WIDTH = { base: 480, sm: 720, md: 520, lg: 900 }
const IMAGE_HEIGHT = { base: 200, sm: 300, md: 300, lg: 440 }

const BlockSection: FunctionComponent<BlockSectionProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }

  return (
    <Container data-testid={TAG} maxW="desktop" display="flex" p={10}>
      <Flex
        as="article"
        data-testid={TAG}
        direction={{ base: 'column-reverse', md: 'row' }}
        mb="16"
        mt="20"
        gap={{ base: '6', md: '10', lg: '20' }}
        maxW="100%"
      >
        <Box maxW="side-block-lg" w="full">
          {hasProperty(pageBlock, 'heading') && pageBlock.heading && (
            <Heading
              as="h2"
              size="lg"
              mb="6"
              noOfLines={3}
              data-contentful-field-id="heading"
              data-contentful-entry-id={pageBlock.sys?.id}
            >
              {pageBlock.heading}
            </Heading>
          )}
          {hasProperty(pageBlock, 'preamble') && pageBlock.preamble && (
            <Text
              fontSize="lg"
              mb="6"
              noOfLines={5}
              data-contentful-field-id="preamble"
              data-contentful-entry-id={pageBlock.sys?.id}
            >
              {pageBlock.preamble}
            </Text>
          )}
          {hasProperty(pageBlock, 'richText') && pageBlock.richText && (
            <RichTextPageContent
              richTextBodyField={pageBlock.richText}
              mb="6"
              data-contentful-field-id="richText"
              data-contentful-entry-id={pageBlock.sys?.id}
            />
          )}
          <NavigationItem
            variant="button"
            {...pageBlock.cta}
            data-contentful-field-id="cta"
            data-contentful-entry-id={pageBlock.cta?.sys.id}
          />
        </Box>

        {hasProperty(pageBlock, 'image') && !!pageBlock.image?.image && (
          <Box maxW={{ base: '100%', lg: 'main-block-lg' }} w={IMAGE_WIDTH} h={IMAGE_HEIGHT}>
            <NextLink href={pageBlock.cta?.targetUrl || '#'}>
              <Image
                data-contentful-field-id="image"
                data-contentful-entry-id={pageBlock.image.sys?.id}
                src={pageBlock.image.image.url}
                alt={pageBlock.image.image.title}
                blurDataURL={pageBlock.image.image.blurDataURL}
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
                minW="100%"
                minH="100%"
              />
            </NextLink>

            {pageBlock.image.caption && (
              <Text mt="4" display="flex" noOfLines={2}>
                {pageBlock.image.caption}
              </Text>
            )}
          </Box>
        )}
      </Flex>
    </Container>
  )
}

export default BlockSection
