import { Checkbox, Heading, Select, VisuallyHidden, FormErrorMessage, FormControl, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import FormInput from 'components/elements/FormInput'
import RichTextPageContent from 'components/elements/RichTextPageContent'
import { EMAIL_REGEX } from 'config/contact'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'

interface ContactFormStepTwoProps {
  isAutoFocus?: boolean
}

const TAG = 'ContactFormStepTwo'

export const getSelectOptions = (options: unknown): string[] =>
  options ? Array.from(options as string).flatMap((_) => Object.values(_)) : []

const ContactFormStepTwo: FunctionComponent<ContactFormStepTwoProps> = ({ isAutoFocus = false }) => {
  const { contactFormDataProcessingCheckboxText } = useContext(GlobalPropsContext)

  const { t } = useTranslation()

  const commonCountries = t('components.contactForm.countries.commonOptions', { count: 1 }, { returnObjects: true })
  const commonCountriesOptions = getSelectOptions(commonCountries)

  const otherCountries = t('components.contactForm.countries.otherOptions', { count: 1 }, { returnObjects: true })
  const otherCountriesOptions = getSelectOptions(otherCountries)

  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <Heading as="h3" size="md">
        {t('components.contactForm.step-two.title')}
      </Heading>

      <FormInput
        autoFocus={isAutoFocus}
        autoComplete="given-name"
        inputMode="text"
        enterKeyHint="next"
        label={t('components.contactForm.fieldsPlaceholders.firstName')}
        placeholder=" "
        {...register('firstName', { required: t('components.contactForm.errors.error-first-name-missing') })}
        isRequired
        {...{ errors }}
      />

      <FormInput
        autoFocus={isAutoFocus}
        autoComplete="family-name"
        inputMode="text"
        enterKeyHint="next"
        label={t('components.contactForm.fieldsPlaceholders.lastName')}
        placeholder=" "
        {...register('lastName', { required: t('components.contactForm.errors.error-last-name-missing') })}
        isRequired
        {...{ errors }}
      />

      <FormInput
        type="email"
        autoComplete="email"
        inputMode="email"
        enterKeyHint="next"
        label={t('components.contactForm.fieldsPlaceholders.email')}
        placeholder=" "
        {...register('emailAddress', {
          required: t('components.contactForm.errors.error-email-missing'),
          pattern: {
            value: EMAIL_REGEX,
            message: t('components.contactForm.errors.error-invalid-email'),
          },
        })}
        isRequired
        {...{ errors }}
      />

      <FormInput
        type="tel"
        autoComplete="tel"
        inputMode="tel"
        enterKeyHint="next"
        label={t('components.contactForm.fieldsPlaceholders.phone')}
        placeholder=" "
        {...register('busPhone')}
        {...{ errors }}
      />

      <FormInput
        autoComplete="organization"
        inputMode="text"
        enterKeyHint="next"
        label={t('components.contactForm.fieldsPlaceholders.your-company')}
        placeholder=" "
        {...register('company', { required: t('components.contactForm.errors.error-company-missing') })}
        isRequired
        {...{ errors }}
      />

      <FormInput
        autoComplete="country-name"
        enterKeyHint="send"
        label={<VisuallyHidden>{t('components.contactForm.fieldsPlaceholders.country')}</VisuallyHidden>}
        placeholder={t('components.contactForm.fieldsPlaceholders.country')}
        as={Select}
        {...register('country')}
        {...{ errors }}
      >
        {commonCountriesOptions.map((country: string) => {
          return (
            <option key={country} value={country}>
              {country}
            </option>
          )
        })}
        <option value="">------------</option>
        {otherCountriesOptions.map((country: string) => {
          return (
            <option key={country} value={country}>
              {country}
            </option>
          )
        })}
      </FormInput>

      <FormControl
        as={Checkbox}
        size="sm"
        {...register('consent', { required: t('components.contactForm.errors.error-field-is-required') })}
        isInvalid={!!errors?.consent}
      >
        {contactFormDataProcessingCheckboxText ? (
          <RichTextPageContent richTextBodyField={contactFormDataProcessingCheckboxText} />
        ) : (
          <Text>{t('components.contactForm.privacy-policy')}</Text>
        )}

        <FormErrorMessage mb={2}>{errors?.consent?.message as string}</FormErrorMessage>
      </FormControl>
    </>
  )
}

ContactFormStepTwo.displayName = TAG

export default ContactFormStepTwo
