import type { QueryFunctionContext } from '@tanstack/react-query'

import { FormField } from 'components/elements/DynamicForm/types'

type Arguments = QueryFunctionContext<[queryFnName: 'getFormFields', url: string]>

function getFormFields({ signal, queryKey: [, url] }: Arguments): Promise<FormField[]> {
  return fetch(url, { method: 'GET', cache: 'no-cache', signal }).then((res) => res.json())
}

export default getFormFields
