import { Card, CardBody, forwardRef, Heading, Stack, CardProps, UnorderedList, ListItem } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import NextLink from 'next/link'
import { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import useResolveDynamicPage from 'utils/Providers/DynamicPage/hooks/useResolveDynamicPage'
import removeEmptyValues from 'utils/removeEmptyValues'

interface ProductCardProps extends CardProps {
  product: Contentful.IPageProductDetail
  ref?: React.Ref<HTMLDivElement>
}

/**
 * Used for E2E tests.
 */
const TAG = 'ProductCard'

const styles = {
  link: {
    w: 'full',
    maxW: { base: 'full', md: 'side-block' },
    h: '100%',
    sx: {
      '&:is(:hover, :focus-visible) > *': { boxShadow: 'lg' },
    },
  },
  card: {
    maxW: { base: 'full', md: 'side-block' },
    borderRadius: 0.5,
    p: 4,
    bg: 'white',
    transitionProperty: 'box-shadow',
    transitionDuration: '0.2s',
  },
  image: {
    maxW: '100%',
    width: { base: 668, md: 290 },
    height: '232',
  },
  body: {
    mt: 4,
    minH: '240px',
  },
  heading: {
    textTransform: 'uppercase',
    mb: 4,
  },
} as const

const ProductCard: FunctionComponent<ProductCardProps> = forwardRef(({ product, ...props }, ref) => {
  const { t } = useTranslation()
  const resolveLink = useResolveDynamicPage()

  return product ? (
    <Stack
      ref={ref}
      as={NextLink}
      href={resolveLink(product.sys.id)}
      aria-label={`${t('pages.products-list.aria.product')};${product.heading}`}
      data-sys-id={product.sys.id}
      {...styles.link}
    >
      <Card data-testid={TAG} variant="unstyled" {...styles.card} {...props}>
        <Image
          data-contentful-field-id="image"
          data-contentful-entry-id={product.heroContent?.sys?.id}
          src={product.heroContent?.image?.url || ''}
          alt={product.heroContent?.image?.title || ''}
          focalPoint={product.heroContent?.focalPoint}
          originalWidth={product.heroContent?.image?.width}
          originalHeight={product.heroContent?.image?.height}
          blurDataURL={product.heroContent?.image?.blurDataURL}
          {...styles.image}
        />
        <CardBody {...styles.body}>
          <Heading
            as="h2"
            size="md"
            {...styles.heading}
            data-contentful-field-id="heading"
            data-contentful-entry-id={product.sys.id}
          >
            {product.heading}
          </Heading>

          <UnorderedList
            variant="dashed"
            data-contentful-field-id="uniqueSellingPoints"
            data-contentful-entry-id={product.sys.id}
          >
            {removeEmptyValues(product?.uniqueSellingPoints?.items).map((feature) => (
              <ListItem key={feature.sys?.id}>{feature.heading}</ListItem>
            ))}
          </UnorderedList>
        </CardBody>
      </Card>
    </Stack>
  ) : null
})

export default ProductCard
