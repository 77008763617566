import { Container, Heading } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import ImageGallery from 'components/elements/ImageGallery'

interface BlockImageGalleryProps extends Contentful.IBlockImageGallery {}

const BlockImageGallery: FunctionComponent<BlockImageGalleryProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }
  return (
    <Container maxW="100vw" p={0} bgColor="primary.grey.100">
      <Container maxW="desktop" p={10}>
        <Heading
          as="h2"
          size="lg"
          mb={6}
          data-contentful-field-id="heading"
          data-contentful-entry-id={pageBlock.sys?.id}
        >
          {pageBlock?.heading}
        </Heading>
        <ImageGallery gallery={pageBlock.gallery?.items} />
      </Container>
    </Container>
  )
}

export default BlockImageGallery
