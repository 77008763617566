import { Card, CardBody, Text, Heading, Link } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import NextLink from 'next/link'
import { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import isValidUrl from 'utils/isValidUrl'

type Props = CardProps & { profile: Contentful.IBlockProfile | undefined }

/**
 * Used for E2E tests.
 */
const TAG = 'ProfileCard'

const ProfileCard: FunctionComponent<Props> = ({ maxW = 'main-block', bgColor = 'white', profile }) => {
  const { t } = useTranslation()

  if (!profile) {
    return null
  }

  return (
    <Card
      data-testid={TAG}
      variant="unstyled"
      direction={{ base: 'column', md: 'row' }}
      bgColor={bgColor}
      maxW={maxW}
      borderRadius={0}
      gap={6}
    >
      <Image
        maxW="100%"
        src={profile.image?.image?.url || ''}
        alt={profile.image?.image?.title || ''}
        width="216"
        height="216"
        borderRadius={2}
        blurDataURL={profile.image?.image?.blurDataURL}
      />
      <CardBody>
        <Heading
          as="h4"
          size="sm"
          mb={1}
          data-contentful-field-id="fullName"
          data-contentful-entry-id={profile.sys?.id}
        >
          {profile.fullName}
        </Heading>
        <Text mb={4} data-contentful-field-id="role" data-contentful-entry-id={profile.sys?.id}>
          {profile.role}
        </Text>
        <Heading as="h4" size="sm" mb={1}>
          {t('pages.blog-article.bio')}
        </Heading>
        <Text mb={6} data-contentful-field-id="bio" data-contentful-entry-id={profile.sys?.id}>
          {profile.bio}
        </Text>
        {profile.linkedInProfileUrl && isValidUrl(profile.linkedInProfileUrl) && (
          <Link
            data-contentful-field-id="linkedInProfileUrl"
            data-contentful-entry-id={profile.sys?.id}
            as={NextLink}
            href={profile.linkedInProfileUrl}
            target="_blank"
            rel="noreferrer"
          >
            {profile.fullName} - {t('pages.blog-article.linkedIn-profile')}
          </Link>
        )}
      </CardBody>
    </Card>
  )
}

export default ProfileCard
