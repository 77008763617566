import { Box, Button, Icon, useBreakpointValue, ButtonProps } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useId } from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'

import 'swiper/css'

import ChevronIcon from 'assets/chevron.svg?component'

const TAG = 'Carousel'

export interface NavProps {
  prev: ButtonProps
  next: ButtonProps
}
interface CarouselProps extends SwiperProps {
  children?: React.ReactNode[]
  slideWidth?: Record<string, number> | number[] | number
  navProps?: NavProps
  id: string
  spaceBetweenSlides?: Record<string, number> | number[] | number
  shouldHideButtons?: boolean
}

const getBreakpointValues = (width: number | Record<string, number> | number[] | undefined) =>
  width ? (typeof width === 'number' ? { base: width } : width) : {}

const Carousel: FunctionComponent<CarouselProps> = ({
  id,
  children,
  slideWidth,
  navProps,
  spaceBetweenSlides = 24,
  shouldHideButtons,
  ...props
}) => {
  const { t } = useTranslation()

  const prevNavRef = React.useRef<HTMLButtonElement>(null)
  const nextNavRef = React.useRef<HTMLButtonElement>(null)

  const width = useBreakpointValue(getBreakpointValues(slideWidth)) || 'auto'
  const marginInlineEnd = useBreakpointValue(getBreakpointValues(spaceBetweenSlides)) || 'auto'

  const sliderWrapper = React.useRef<HTMLDivElement>(null)
  const slidesCount = children?.length || 0
  // '24' is used instead of the `spaceBetweenSlides` value because according to the design 24px is the space between slides on large screens;
  // `spaceBetweenSlides` is usually set to an object, eg. { base: 8, lg: 24}
  const slidesWidth = (width === 'auto' ? 0 : width) * slidesCount + 24 * (slidesCount - 1)
  const hideButtons = shouldHideButtons ? (sliderWrapper?.current?.clientWidth || 0) - slidesWidth >= 0 : false

  const reactId = useId() // To avoid duplicate ids when using multiple carousels on the same page
  const swiperId = `swiper-${TAG}-${id}-${reactId}`

  return (
    <Box
      ref={sliderWrapper}
      pos="relative"
      sx={{ '.swiper-slide': { mb: 4, mt: 4 /* Saves space for hover effect - shadow */ } }}
    >
      <Swiper
        id={swiperId}
        modules={[Navigation]}
        slidesPerView="auto"
        navigation={{
          prevEl: prevNavRef.current,
          nextEl: nextNavRef.current,
        }}
        {...props}
      >
        {React.Children.map(children, (item, index) => (
          <SwiperSlide style={{ width, height: 'auto', marginInlineEnd }} key={index}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>

      <Button
        variant="slider"
        ref={prevNavRef}
        left={-10}
        aria-controls={swiperId}
        aria-label={t('components.carousel.prev-button')}
        color="primary.white"
        hidden={hideButtons}
        {...navProps?.prev}
      >
        <Icon aria-hidden="true" as={ChevronIcon} boxSize={6} transform="rotate(180deg)" />
      </Button>
      <Button
        variant="slider"
        ref={nextNavRef}
        right={-10}
        aria-controls={swiperId}
        aria-label={t('components.carousel.next-button')}
        color="primary.white"
        hidden={hideButtons}
        {...navProps?.next}
      >
        <Icon aria-hidden="true" as={ChevronIcon} boxSize={6} />
      </Button>
    </Box>
  )
}
Carousel.displayName = TAG

export default Carousel
