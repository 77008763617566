import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react'
import React, { FunctionComponent, useCallback, useState } from 'react'

import Carousel, { NavProps } from 'components/elements/Carousel'
import ImagePreview from 'components/elements/ImagePreview'
import GalleryCard from 'components/modules/Cards/GalleryCard'
import removeEmptyValues from 'utils/removeEmptyValues'

type ImageGalleryProps = {
  gallery: Contentful.IBlockLinkList[] | undefined
  navProps?: NavProps
}

const DEFAULT_NAV_PROPS: NavProps = {
  prev: {
    left: -10,
    top: { base: '100px', lg: '220px' },
  },
  next: {
    right: -10,
    top: { base: '100px', lg: '220px' },
  },
}

/**
 * Used for E2E tests.
 */
const TAG = 'ImageGallery'

const ImageGallery: FunctionComponent<ImageGalleryProps> = ({ gallery, navProps }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handlePreviewImage = useCallback(
    (currentGalleryIndex: number, currentImageIndex: number) => {
      setCurrentGalleryIndex(currentGalleryIndex)
      setCurrentImageIndex(currentImageIndex)
      onOpen()
    },
    [onOpen],
  )

  return (
    <Box>
      <Tabs>
        <TabList>
          {removeEmptyValues(gallery).map((item) => (
            <Tab key={item.sys.id}>{item.heading}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {removeEmptyValues(gallery).map((item, galleryIndex) => (
            <TabPanel key={item.sys.id}>
              <Box py={4}>
                <Carousel id={`${TAG}-${item.sys.id}`} navProps={navProps || DEFAULT_NAV_PROPS}>
                  {(removeEmptyValues(item?.links?.items) as Contentful.IComponentMediaWrapper[]).map(
                    (item, imageIndex) => (
                      <GalleryCard
                        key={item?.title}
                        currentGalleryIndex={galleryIndex}
                        currentImageIndex={imageIndex}
                        content={item.caption}
                        media={item.image}
                        vimeoId={item.vimeoId}
                        mediaType={item.mediaType}
                        onClick={handlePreviewImage}
                      />
                    ),
                  )}
                </Carousel>
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <ImagePreview
        gallery={gallery?.[currentGalleryIndex]}
        currentImageIndex={currentImageIndex}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  )
}

ImageGallery.displayName = TAG

export default ImageGallery
