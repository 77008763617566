import { Container, Flex, Heading, Stack } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import NavigationItem from 'components/elements/NavigationItem'
import RichTextPageContent from 'components/elements/RichTextPageContent'

interface BlockPromotionProps extends Contentful.IBlockPromotion {}

const TAG = 'BlockPromotion'

const listItemStyles = {
  ul: {
    margin: 0,
  },
  li: {
    position: 'relative',
    mb: 2,
    // before element as chevron for list items
    _before: {
      display: 'none',
    },
    _after: {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0.18em 0.18em 0 0',
      borderColor: 'primary.red',
      display: 'inline-block',
      height: '0.5em',
      width: '0.5em',
      left: -6,
      top: 2,
      transform: 'rotate(45deg)',
    },
  },
}
const BlockPromotion: FunctionComponent<BlockPromotionProps> = (pageBlock) => {
  if (!pageBlock) {
    return null
  }

  return (
    <Container data-testid={TAG} maxW="desktop-content" display="flex" p={0} my={10}>
      <Flex w="full" direction={{ base: 'column-reverse', lg: 'row' }} bg="black" color="primary.white">
        <Stack
          spacing={8}
          bg="black"
          color="primary.white"
          alignItems="flex-start"
          maxW="drawer-lg"
          w="full"
          mb={4}
          pl={{ base: 10, xl: '100px' }}
          pr={{ base: 10 }}
          py={10}
        >
          <Heading as="h2" size="md" data-contentful-field-id="heading" data-contentful-entry-id={pageBlock.sys?.id}>
            {pageBlock.heading}
          </Heading>

          {pageBlock.richText && (
            <RichTextPageContent
              data-contentful-field-id="richText"
              data-contentful-entry-id={pageBlock.sys?.id}
              sx={listItemStyles}
              richTextBodyField={pageBlock.richText}
            />
          )}

          <NavigationItem
            variant="buttonOutline"
            minW="150px"
            data-contentful-field-id="cta"
            data-contentful-entry-id={pageBlock.cta?.sys?.id}
            {...pageBlock.cta}
          />
        </Stack>
        {pageBlock?.media?.image?.url && (
          <Flex flexBasis={0} flexGrow={1}>
            <Image
              data-contentful-field-id="image"
              data-contentful-entry-id={pageBlock.media.sys?.id}
              style={{ width: '100%', height: '100%' }}
              width={pageBlock.media.image.width || ''}
              height={pageBlock.media.image.height || ''}
              src={pageBlock.media.image.url || ''}
              alt={pageBlock.media.image.title || ''}
              blurDataURL={pageBlock.media.image.blurDataURL}
              role="presentation"
              loading="lazy"
              marginInline="auto"
              userSelect="none"
              bg="transparent"
            />
          </Flex>
        )}
      </Flex>
    </Container>
  )
}

export default BlockPromotion
